
//
// Lib loading & init
//
import Alpine from 'alpinejs';
import intersect from '@alpinejs/intersect';
import vivus from 'vivus';
import logoview from './components/logoview.js'

// Init
window.Alpine = Alpine;
const Vivus = vivus;

// Run Alpine
queueMicrotask(() => {
    Alpine.data('logoview', logoview)
    Alpine.plugin(intersect),
    Alpine.start()
});
